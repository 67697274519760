jQuery.fn.center = function () { //used the centre something in the middle of the screen
  this.css("position", "absolute");
  this.css("top", ($(window).height() - this.height()) / 2 + $(window).scrollTop() + "px");
  this.css("left", ($(window).width() - this.width()) / 2 + $(window).scrollLeft() + "px");
  return this;
};


//Bind keyup functions to text inputs and textareas for the text counter
$(document).ready( function () {
  $("input[type=\"text\"]").keyup(function() {
    var text_length = $(this).val().length;
    var text_max = $(this).data("parsleyMaxlength");
    var text_remaining = text_max - text_length;
    $(this).siblings(".count_message").html(text_remaining + " characters remaining");
  });
} );

$(document).ready( function () {
  $("input[type=\"number\"]").keyup(function() {
    var text_length = $(this).val().length;
    var text_max = $(this).data("parsleyMaxlength");
    var text_remaining = text_max - text_length;
    $(this).siblings(".count_message").html(text_remaining + " characters remaining");
  });
} );

$(document).ready( function () {
  $("textarea").keyup(function() {
    var text_length = $(this).val().length;
    var text_max = $(this).data("parsleyMaxlength");
    var text_remaining = text_max - text_length;
    $(this).siblings(".count_message").html(text_remaining + " characters remaining");
  });
} );

$(document).ready(function(){
  $(".phonenumbermask").inputmask("999999999[999]",{greedy: false,showMaskOnHover: false, clearMaskOnLostFocus: true,showMaskOnFocus: false,skipOptionalPartCharacter:true,autoUnmask:true});
  $(".emailmask").inputmask("email");
  $(".postcodemask").inputmask("Regex", { regex: "[a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?\\s?[0-9][a-zA-Z]{2},\i" });

  $(".standard-datepicker-start-now").datepicker({
    format: "dd/mm/yyyy",
    startDate: "0d",
    autoclose:true,
  });

  $(".standard-datepicker-end-2050").datepicker({
    format: "dd/mm/yyyy",
    startDate: "0d",
    endDate: "+35y",
    autoclose:true,
  });

  $(".standard-datepicker-end-now").datepicker({
    format: "dd/mm/yyyy",
    endDate: "0d",
    autoclose:true,
  });

  $(".standard-datepicker-day-month").datepicker( {
    changeYear: false,
    dateFormat: 'dd/mm',
  }).focus(function () {
    $(".ui-datepicker-year").hide();
  });

  $(".standard-clockpicker-input").clockpicker({
    placement: "left",
    align: "left",
    autoclose: true,
    donetext:"",
  });

});


function reregistermasks(){
  $(".phonenumbermask").inputmask("999999999[999]",{greedy: false,showMaskOnHover: false, clearMaskOnLostFocus: true,showMaskOnFocus: false,skipOptionalPartCharacter:true,autoUnmask:true});
  $(".emailmask").inputmask("email");
  $(".postcodemask").inputmask("Regex", { regex: "[a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?\\s?[0-9][a-zA-Z]{2},\i" });

  $(".standard-datepicker-start-now").datepicker({
    format: "dd/mm/yyyy",
    startDate: "0d",
    autoclose:true,
  });

  $(".standard-datepicker-end-now").datepicker({
    format: "dd/mm/yyyy",
    endDate: "0d",
    autoclose:true,
  });

  $(".standard-clockpicker-input").clockpicker({
    placement: "left",
    align: "left",
    autoclose: true,
    donetext:"",
  });

  $(".standard-datepicker-end-2050").datepicker({
    format: "dd/mm/yyyy",
    startDate: "0d",
    endDate: "+35y",
    autoclose:true,
  });
}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    beforeSend:function(){
      // this is where we append a loading spinner to loader civ
      $('.loader').append('<div id="progress"><img src="/img/wheel.gif"><br><span class="loadertext">Loading...</span></div>');
      $('#progress').center(); //call the center function, centres the progress div in the middle of the page
    },
    complete:function(data){
      $('.loader').empty();
    }
});
